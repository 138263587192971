import React, { useEffect, useState } from 'react'
import { silentAuth } from '../../utils/auth0'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { IntlProvider } from 'gatsby-plugin-intl'
import { TAG_ID, udtechServerMode } from '../../utils/consts'
import { maxmindGeoIp } from '../../utils/maxmindGeoIp'
import moment from 'moment'
import { pushToDataLayer } from '../../utils/pushToDataLayer'
import { Loader } from '../loader'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const domain = process.env.GATSBY_DOMAIN_URL
    ? process.env.GATSBY_DOMAIN_URL.replace('https://', '').replace('/', '')
    : 'circa-dev.udtech.global'

  const getIpData = async () => {
    const newData = await maxmindGeoIp()
    if (newData) {
      const ip = newData.traits.ip_address
      const env = udtechServerMode
      const session = `${env} - ${ip} (${moment().format('YYYY-MM-DD')})`

      let data: any = {
        event: 'site_visited',
        siteSession: session,
      }
      pushToDataLayer(data)
      sessionStorage.setItem('fullStoryData', 'true')
    }
  }

  useEffect(() => {
    document.cookie = `user_from_new_site=true; domain=${domain}`
    silentAuth(() => setLoading(false))
    getIpData()
  }, [])

  if (loading) return <></>

  return (
    <Provider store={store || {}}>
      <IntlProvider locale='es'>
        <noscript key={1}>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${TAG_ID}`}
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {children}
      </IntlProvider>
    </Provider>
  )
}

export default Layout
